import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  FormLabel,
  Toolbar,
  Chip,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import {Tabs, Tab} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {FormGroup, FormControl} from "@material-ui/core";
import TabContainer from "./shares/react_tab_container";
import language from "../layout/language";

import Select from "@material-ui/core/Select";
import ImageControl from "./shares/react_image_control";


// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/ionicons.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/css/third_party/ionicons.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import classNames from "classnames";

import ReactAutoSuggest from './shares/react_auto_suggest';
import UploadItem from './shares/react_upload';

import Utils from "../api/api";
import theme, {styles} from "../layout/theme";

class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      students: [],
      class_students: [],
      status: [
        'Draft', 'Publish', 'UnPublish'
      ],
      project: {
        author: "",
        classId: "",
        date: new Date().toString(),
        urlVN: "",
        urlEN: "",
        thumb: "",
        titleVN: "",
        subtitleVN: "",
        summaryVN: "",
        detailVN: "",
        descriptionVN: "",
        titleEN: "",
        subtitleEN: "",
        summaryEN: "",
        detailEN: "",
        descriptionEN: "",
        status: 0,
        dateCreated: new Date(),
        pinned: false,
        file: ''
      },
      error: {
        vn: false,
        en: false,
        titleVN_error: false,
        summaryVN_error: false,
        detailVN_error: false,
        titleEN_error: false,
        summaryEN_error: false,
        detailEN_error: false
      },
      failed: false,
      message: "",
      isUpdate: false,
      tab: 0,
      open: false,
      options: {
        'empty': 'empty'
      }
    };
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData("schedules", 1, 10000, "0,0", JSON.stringify({status: '1,3'}), 1, function (data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({classes: data.results});
      }
    });

    Utils.getListData("accounts", 1, 10000, "0,0", null, 1, function (data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({students: data.results});
      }
    });

    Utils.getBlockContents(function (options) {
      $this.setState({options: options});
    });

    if (params.id !== undefined) {
      $this
        .setState({
          isUpdate: true
        }, function () {
          Utils
            .getSingleData("projects", params.id, function (res) {
              if (res.status === "success" && res.results !== undefined) {
                var data = {},
                  item = res.results;
                for (var property in item) {
                  if (item.hasOwnProperty(property)) {
                    data[property] = item[property];
                  }
                }
                data._id = item._id;
                if (data.urlVN === undefined)data.urlVN = '';
                if (data.urlEN === undefined)data.urlEN = '';
                if (data.dateCreated === undefined)data.dateCreated = new Date();

                Utils.getSingleData('schedule',data.classId,function(res){
                  if(res.status==='success') {
                    //console.log(res.results.lecturers);
                    var arr = [];
                    $this.state.students.map((x,i)=>{
                      res.results.students.map((y,z)=>{
                        if(x._id===y) {
                          arr.push(x);
                        }
                      });
                    });
                    $this.setState({class_students:arr},function(){
                      $this.setState({project: data});
                    });
                  } else {
                    $this.setState({project: data});
                  }
                });

              }
            });
        });
    }
  }
  onEditorStateChange(field, value) {
    var project = this.state.project,
      error = this.state.error;
    project[field] = value;
    error[field + "_error"] = value === "";

    if (field.indexOf("summary") !== -1) {
      var locale = field.replace("summary", "");
      var temporalDivElement = document.createElement("div");
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = value;
      // Retrieve the text property of the element (cross-browser support)
      var text = temporalDivElement.textContent || temporalDivElement.innerText || "";
      project["description" + locale] = text.substring(0, 200);
    }

    this.setState({project: project, error: error});
  }
  handleImageChange(value) {
    var project = this.state.project;
    project.thumb = value;
    this.setState({project: project});
  }
  handleFieldChange(e) {
    var project = this.state.project;
    project[e.target.id] = e.target.value;
    if(e.target.id!=='pinned'){
      project[e.target.id] = e.target.value;
    } else {
      if(project[e.target.id]=='true'||project[e.target.id]===true){
        project[e.target.id] = 'false';
      } else {
        project[e.target.id] = 'true';
      }
    }
    if (e.target.type == "textarea") {
      project[e.target.id] = e
        .target
        .value
        .substring(0, 200);
    }
    this.setState({project: project});
  }
  handleValidate(e, flag) {
    var $this = this,
      project = this.state.project,
      obj = this.state.error;

    project[e.target.id] = e.target.value;

    var p = e.target.id + "_error";

    if (e.target.value.trim() === "" || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if (e.target.id === "titleVN") {
      var urlVN = Utils.getFriendlyURL(e.target.value.trim() + '-' + project.author);
      project.urlVN = urlVN;
    }
    if (e.target.id === "titleEN") {
      var urlEN = Utils.getFriendlyURL(e.target.value.trim() + '-' + project.author);
      project.urlEN = urlEN;
    }

    this.setState({
      project: project,
      error: obj
    }, () => {
      if (!$this.state.error.titleVN_error && !$this.state.error.summaryVN_error && !$this.state.error.detailVN_error) {
        obj["vn"] = false;
      } else {
        obj["vn"] = true;
      }
      if (!$this.state.error.titleEN_error && !$this.state.error.summaryEN_error && !$this.state.error.detailEN_error) {
        obj["en"] = false;
      } else {
        obj["en"] = true;
      }
      $this.setState({error: obj});
    });
  }
  onUpdateSchedule(event) {
    const $this = this;
    var project = $this.state.project;

    project.classId = event.target.value;

    //console.log(value);

    this.setState({project:project},function(){
      Utils.getSingleData('schedule',project.classId,function(data){
        if(data.status==='success') {
          //console.log(data.results.lecturers);
          var arr = [];
          $this.state.students.map((x,i)=>{
            data.results.students.map((y,z)=>{
              if(x._id===y) {
                arr.push(x);
              }
            });
          });
          $this.setState({class_students:arr});
        }
      });
    });

  }
  onUpdateStudent(value,title) {
    const $this = this;
    var project = $this.state.project;

    project.author = value;
    project.urlVN = Utils.getFriendlyURL(project.titleVN + '-' + value);
    project.urlEN = Utils.getFriendlyURL(project.titleEN + '-' + value);

    this.setState({project:project});
  }
  _save() {
    var $this = this;
    var data = this.state.project;

    data.status = parseInt(data.status);

    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils
        ._update("project", data, function (res) {
          //console.log(res);
          if (res.status === "success") {
            $this
              .context
              .router
              .history
              .push("/projects/");
          } else {
            $this.setState({
              failed: true,
              message: JSON.stringify(res.message)
            });
          }
        });
    } else {
      Utils
        ._add("project", data, function (res) {
          if (res.status === "success") {
            $this
              .context
              .router
              .history
              .push("/projects/");
          } else {
            $this.setState({
              failed: true,
              message: JSON.stringify(res.message)
            });
          }
        });
    }
  }
  handleSave(e, flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true});
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch (flag) {
        case "vn":
          obj["titleVN_error"] = this.state.project.titleVN === "";
          obj["summaryVN_error"] = this.state.project.summaryVN === "";
          obj["detailVN_error"] = this.state.project.detailVN === "";
          obj["vn"] = obj["titleVN_error"] || obj["summaryVN_error"] || obj["detailVN_error"];
          this.setState({
            error: obj
          }, function () {
            if (!$this.state.error[flag])
              $this._save();
            }
          );
          break;
        case "en":
          obj["titleEN_error"] = this.state.project.titleEN === "";
          obj["summaryEN_error"] = this.state.project.summaryEN === "";
          obj["detailEN_error"] = this.state.project.detailEN === "";
          obj["en"] = obj["titleEN_error"] || obj["summaryEN_error"] || obj["detailEN_error"];
          this.setState({
            error: obj
          }, function () {
            if (!$this.state.error[flag])
              $this._save();
            }
          );
          break;
          // case 'kr':   obj['titleKR_error'] = this.state.project.titleKR === '';
          // obj['summaryKR_error'] = this.state.project.summaryKR === '';
          // obj['detailKR_error'] = this.state.project.detailKR === '';   obj['kr'] =
          // obj['titleKR_error'] || obj['summaryKR_error'] || obj['detailKR_error'];
          // this.setState({error:obj},function(){     if(!$this.state.error[flag])
          // $this._save();   });   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this
      .context
      .router
      .history
      .push("/projects/");
  }
  handleChangeTab = (event, value) => {
    this.setState({tab: value});
  };
  handleClickOpen = () => {
    this.setState({open: true});
  };
  handleClose = () => {
    this.setState({open: false});
  };
  selectImg(value) {
    var $this = this,
      obj = this.state.project;
    obj.thumb = value;
    this.setState({
      project: obj
    }, function () {
      $this.handleClose();
    });
  }
  handleChange(e) {
    var $this = this,
      obj = this.state.project;
    obj.type = e.target.value;
    this.setState({project: obj});
  }
  handleCopy() {
    var data = this.state.project;
    data.titleEN = data.titleVN;
    data.subtitleEN = data.subtitleVN;
    data.summaryEN = data.summaryVN;
    data.detailEN = data.detailVN;
    data.descriptionEN = data.descriptionVN;
    this.setState({project: data});
  }
  onUploadComleted(data){
    console.log(data);
    var project = this.state.project;
    if(data.status==='success' && data.results.length) {
      project.file = data.results[0].link;
    }
    this.setState({project:project});
  }
  render() {
    const $this = this;
    const classes = this.props.classes;
    const value = this.state.tab;
    const project = this.state.project;
    const editorConfigBasic = Utils.setupBasicEditor();
    const editorConfig = Utils.setupEditor('project', this.state.options);

    var arrStudents = [];

    $this.state.class_students.map((x,i)=>{
      arrStudents.push({title:x.fullname,value:x._id});
    });

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa bài viết (project)
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt"/>
            <Tab label="English"/> {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        <form className={classes.form} noValidate>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <Typography className={classes.label} type="caption">
                Lớp/Khóa học
              </Typography>
              <Select
                native
                id="classId"
                value={project.classId}
                onChange={event => this.onUpdateSchedule(event)}>
                <option key={0} value="">
                  ...
                </option>
                {this
                  .state
                  .classes
                  .map((item, index) => (
                    <option key={index + 1} value={item._id}>
                      {item.classnameVN}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Học viên</Typography>
              <FormControl className={classes.chipRow}>
                {project.author !== undefined &&
                  project.author !== null &&
                  $this.state.class_students.map((y,z)=>{
                    if(project.author===y._id) {
                      return (
                        <Chip
                          label={y['fullname']}
                          key={z}
                          className={classes.chip}
                        />
                      )
                    }
                  })
                }
              </FormControl>
              <ReactAutoSuggest value={project.author} className={classes.formRow} data={arrStudents} onChange={(v,t)=>this.onUpdateStudent(v,t)}></ReactAutoSuggest>
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col6}>
              <FormControlLabel
                control={
                  <Switch
                    id="pinned"
                    checked={project.pinned==='true'}
                    onChange={(event)=>this.handleFieldChange(event)}
                  />
                }
                label="Ghim"
              />
            </FormControl>
            <FormControl className={classes.col6}>
              <UploadItem type='file' className='file-control' target={'projects'} acceptedTypes={['.zip','application/zip','application/x-zip-compressed']} onComplete={(l)=>this.onUploadComleted(l)}></UploadItem>
            </FormControl>
          </FormGroup>
        </form>
        {value === 0 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(classes.error, !this.state.error.vn && classes.hide)}>
                {language.VN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(classes.error, !this.state.failed && classes.hide)}>
                {this.state.message}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                aria-label="copy"
                onClick={() => this.handleCopy()}>
                Copy to [EN]
              </Button>
              <TextField
                id="titleVN"
                label={language.VN.title}
                className={classes.textField}
                value={project.titleVN}
                onChange={event => this.handleValidate(event, "vn")}
                onBlur={event => this.handleValidate(event, "vn")}
                required={true}
                error={this.state.error.titleVN_error}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <TextField
                id="urlVN"
                label="Link [SEO]"
                className={classes.textField}
                value={project.urlVN}
                disabled={true}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <TextField
                id="subtitleVN"
                label={language.VN.subTitle}
                className={classes.textField}
                value={project.subtitleVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <TextField
                    id="date"
                    className="form-control-no-bottom"
                    label={language.VN.date}
                    value={Utils.formatDate(project.date)}
                    type="date"
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                    InputLabelProps={{
                    shrink: true
                  }}/>
                </FormControl>
                <FormControl className={classes.col6}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    className={classes.selectMultiLevel}
                    id="status"
                    value={project.status}
                    onChange={event => this.handleFieldChange(event)}>
                    <option value="" key={0}>
                      ...
                    </option>
                    {this
                      .state
                      .status
                      .map((item, index) => {
                        return (
                          <option key={index + 1} value={index}>
                            {item}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <ImageControl
                  value={project.thumb}
                  handleChange={value => this.handleImageChange(value)}/>
              </FormGroup>
              <TextField
                id="descriptionVN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={project.descriptionVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryVN_error}>
                {language.VN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={project.summaryVN}
                onModelChange={value => this.onEditorStateChange("summaryVN", value)}/>
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailVN_error}>
                {language.VN.detail}
              </FormLabel>
              {this.state.options['empty'] !== 'empty' && (<FroalaEditor
                tag="textarea"
                config={editorConfig}
                model={project.detailVN}
                onModelChange={value => this.onEditorStateChange("detailVN", value)}/>)}
              <FormGroup row className={classes.formRow}>
                <Button color="default" onClick={event => this.handleCancel(event)}>
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "vn")}>
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(classes.error, !this.state.error.en && classes.hide)}>
                {language.EN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(classes.error, !this.state.failed && classes.hide)}>
                {this.state.message}
              </Typography>
              <TextField
                id="titleEN"
                label={language.EN.title}
                className={classes.textField}
                value={this.state.project.titleEN}
                onChange={event => this.handleValidate(event, "en")}
                onBlur={event => this.handleValidate(event, "en")}
                required={true}
                error={this.state.error.titleEN_error}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <TextField
                id="urlEN"
                label="Link [SEO]"
                className={classes.textField}
                value={project.urlEN}
                disabled={true}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <TextField
                id="subtitleEN"
                label={language.EN.subTitle}
                className={classes.textField}
                value={this.state.project.subtitleEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <TextField
                id="descriptionEN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={project.descriptionEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                shrink: true
              }}/>
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryEN_error}>
                {language.EN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={project.summaryEN}
                onModelChange={value => this.onEditorStateChange("summaryEN", value)}/>
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailEN_error}>
                {language.EN.detail}
              </FormLabel>
              {this.state.options['empty'] !== 'empty' && (<FroalaEditor
                tag="textarea"
                config={editorConfig}
                model={project.detailEN}
                onModelChange={value => this.onEditorStateChange("detailEN", value)}/>)}
              <FormGroup row className={classes.formRow}>
                <Button color="default" onClick={event => this.handleCancel(event)}>
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "en")}>
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
      </div>
    );
  }
}

ProjectDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
ProjectDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ProjectDetail);
